<template>
    <div class="header_wrap position-relative">
        <Header class="header flex-between-center p-3 py-2 position-fixed top-0 w-100">
            <div class="fs-6">
                <span class="text-yellow-1 text-opacity-50" @click="ClickEvent()">
                    <img src="@/assets/img/layout_icon/home.svg" alt="prev" width="30" v-if="link == '/'">
                    <img src="@/assets/img/layout_icon/chevron_left.svg" alt="prev" width="30" v-else>
                </span>
            </div>
            <div class="tit">
                <span class="d-block text-yellow-1 fs-5 fw-bold text-truncate">{{ title }}</span>
            </div>
            <div>
                <div class="menu">
                    <div class="position-relative"  v-if="share != ''">
                        <span class="border-0 ms-2" @click="share_open = !share_open">
                            <img src="@/assets/img/layout_icon/share.svg" alt="share" width="30">
                        </span>
                        <div class="dimm position-fixed top-0 start-0 w-100 h-100" @click="share_open=false" v-if="share_open"></div>
                        <div class="dropdown_box text-body bg-white rounded-1 position-absolute top-100 end-0 shadow mt-2 w-px-150" v-show="share_open">
                            <ul class="text-capitalize last-border-0">
                                <li class="p-2 border-bottom" v-for="(item,index) in share_list" :key="index" @click="Share(item.id)">
                                    <img :src="require(`@/assets/img/layout_icon/${item.icon}`)" v-if="item.icon_type=='img'" width="22">
                                    <i :class="`fs-px-18 d-inline-block w-px-22 text-center far fa-${item.icon}`" v-if="item.icon_type=='txt'"></i>
                                    <span class="ms-2">{{item.title}}</span>
                                </li>
                            </ul>
                        </div>
                        <!-- <span class="border-0 ms-2" @click="$router.push('/mypage')">
                            <img src="@/assets/img/user-icon.svg" alt="user-icon" height="34"  v-if="profile_img == ''">
                            <img :src="profile_img" alt="profile" width="34" height="34" class="rounded-circle object-fit-cover" v-else>
                        </span> -->
                    </div>
                </div>
                <div class="flex-start-center">
                    <router-link to="/" class="me-2 position-relative" v-if="right_home_btn">
                        <img src="@/assets/img/layout_icon/home.svg" alt="prev" width="30">
                    </router-link>
                    <router-link to="/mydeal/buy/wish-list" class="me-2 position-relative" v-if="wish">
                        <img src="@/assets/img/layout_icon/heart_y.svg" alt="my wish" width="26">
                        
                    </router-link>
                    <span class="border-0 ms-2" v-if="profile" @click="$router.push('/mypage')">
                        <img src="@/assets/img/user-icon.svg" alt="user-icon" height="34"  v-if="profile_img == ''">
                        <img :src="profile_img" alt="profile" width="34" height="34" class="rounded-circle object-fit-cover" v-else>
                    </span>
                </div>
            </div>
            
        </Header>
    </div>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: '',
            },
            link: {
                type: String,
                default: '',
            },
            share: {
                type: String,
                default: ''
            },
            wish: {
                type: Boolean,
                default: false
            },
            profile: {
                type: Boolean,
                default: false
            },
            right_home_btn: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return{
                share_list: [
                    {
                        id: 0,
                        icon: 'facebook-circular.svg',
                        icon_type: 'img',
                        title: 'Facebook',
                    },
                    {
                        id: 1,
                        icon: 'twitter-circular.svg',
                        icon_type: 'img',
                        title: 'Twitter',
                    },
                    {
                        id: 2,
                        icon: 'telegram-circular.svg',
                        icon_type: 'img',
                        title: 'Telegram',
                    },
                    {
                        id: 3,
                        icon: 'instagram.svg',
                        icon_type: 'img',
                        title: 'instargram',
                    },
                    {
                        id: 4,
                        icon: 'link',
                        icon_type: 'txt',
                        title: 'Copy Link',
                    },
                ],
                share_open: false,
                profile_img: this.$store.state.profile,
            }
        },
        methods: {
            ClickEvent() {
                if (this.link == '') {
                    this.goBack();
                } else {
                    this.goLink();
                }
            },
            goBack() {
                this.$router.go(-1);
            },
            goLink() {
                this.$router.push(this.link);
            },

            Share(id) {
                switch (id) {
                    case 0:
                        this.$copy(this.share)
                        var thisUrl = this.share;
                        var snsTitle = "OneCoinBuy";
                        var url = "http://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(thisUrl);
                        window.open(url, "");
                        break;
                    case 1:
                        var thisUrl = this.share;
                        var snsTitle = "OneCoinBuy";
                        var url = "http://twitter.com/share?url="+encodeURIComponent(thisUrl)+"&text="+encodeURIComponent(snsTitle);
                        window.open(url, "tweetPop");
                        break;
                    case 2:
                        var thisUrl = this.share;
                        
                        var snsTitle = "OneCoinBuy";
                        var url =`https://telegram.me/share/url?url=${thisUrl}&text=${snsTitle}`;
                        window.open(url, "telegram");
                        break;
                    case 3:
                        this.$copy(this.share)
                        var thisUrl = this.share;
                        var snsTitle = "OneCoinBuy";
                        var url = "https://www.instagram.com/?url="+encodeURIComponent(thisUrl);
                        window.open(url, "");
                        break;
                    case 4:
                        this.$copy(this.share)
                        this.$pp({
                            msg: 'Copied', 
                            is_confirm: false, 
                            auto_hide:true
                        })
                        break;
                    default:
                        break;
                }
                this.share_open = false;
            }
        },
    }
</script>
<style lang="scss" scoped>
    .menu .badge {
        top: -2px;
        right: -8px;
    }

    .header_wrap {
        padding-bottom: 55px;
        z-index: 100;
        .header {
            max-width: 768px; //반응형

            height: 55px;
            background-color: #36001f;

            .tit{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 70%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
            }
        }
    }
</style>